import React from 'react';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import HeaderBanner from '../components/HeaderBanner';
import { useStaticQuery, graphql } from 'gatsby';
import ServicePreview from '../components/services/ServicePreview';
import withTranslations from '../components/hoc/withTranslations';
import { useTranslation } from 'react-i18next';

const Services = props => {
  const { t } = useTranslation();
  let data = useStaticQuery(graphql`
    query {
      services: file(relativePath: { eq: "services2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 5000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mobile: file(relativePath: { eq: "services/mobile.png" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      web: file(relativePath: { eq: "services/web.png" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      systems: file(relativePath: { eq: "services/systems.png" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      cloud: file(relativePath: { eq: "services/cloud.png" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      consult: file(relativePath: { eq: "services/consult.png" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      courses: file(relativePath: { eq: "services/courses.png" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const services = [
    {
      name: t('services.servicesContent.mobileApps.name'),
      id: 'mobile-apps',
      buttonText: t('services.servicesContent.mobileApps.buttonText'),
      description: t('services.servicesContent.mobileApps.description'),
      imageFluid: data.mobile.childImageSharp.fluid,
      route: `#contact>${t('contact.selectProjects.options.mobileApps')}`
    },
    {
      name: t('services.servicesContent.webDevelopment.name'),
      id: 'web-development',
      buttonText: t('services.servicesContent.webDevelopment.buttonText'),
      description: t('services.servicesContent.webDevelopment.description'),
      imageFluid: data.web.childImageSharp.fluid,
      route: `#contact>${t('contact.selectProjects.options.webDevelopment')}`
    },
    {
      name: t('services.servicesContent.cloudServices.name'),
      id: 'cloud-services',
      buttonText: t('services.servicesContent.cloudServices.buttonText'),
      description: t('services.servicesContent.cloudServices.description'),
      imageFluid: data.cloud.childImageSharp.fluid,
      route: `#contact>${t('contact.selectProjects.options.cloudServices')}`
    },
    {
      name: t('services.servicesContent.customSystems.name'),
      id: 'custom-systems',
      buttonText: t('services.servicesContent.customSystems.buttonText'),
      description: t('services.servicesContent.customSystems.description'),
      imageFluid: data.systems.childImageSharp.fluid,
      route: `#contact>${t('contact.selectProjects.options.customSystems')}`
    },
    {
      name: t('services.servicesContent.consulting.name'),
      id: 'consulting',
      buttonText: t('services.servicesContent.consulting.buttonText'),
      description: t('services.servicesContent.consulting.description'),
      imageFluid: data.consult.childImageSharp.fluid,
      route: `#contact>${t('contact.selectProjects.options.consulting')}`
    },
    {
      name: t('services.servicesContent.coursesAndWorkshops.name'),
      id: 'courses-and-workshops',
      buttonText: t('services.servicesContent.coursesAndWorkshops.buttonText'),
      description: t('services.servicesContent.coursesAndWorkshops.description'),
      imageFluid: data.courses.childImageSharp.fluid,
      route: '/courses'
    }
  ];

  return (
    <Layout section='SERVICES' manualMargin manualPadding>
      <Seo
        title={t('services.seoTitle')}
        description={t('services.seoDescription')}
        keywords={[
          'desarrollo web',
          'apps',
          'apps moviles',
          'mobile apps',
          'custom systems',
          'sistemas especializados',
          'escritorio'
        ]}
      />
      <div className='header'>
        <HeaderBanner
          title={t('services.headerTitle')}
          subtitle={t('services.headerSubTitle')}
          imageFluid={data.services.childImageSharp.fluid}
        />
      </div>
      <div className='dummy-space' />
      <div className='services global-section global-margin'>
        {services.map((service, index) => (
          <div key={index}>
            <div id={service.id} style={{ height: '80px' }} />
            <ServicePreview
              reverse={index % 2 == 0}
              name={service.name}
              buttonText={service.buttonText}
              imageFluid={service.imageFluid}
              description={service.description}
              route={service.route}
            />
          </div>
        ))}
      </div>
      <style jsx>{`
        .dummy-space {
          height: 52vh;
        }
        .header {
          position: absolute;
          left: 0;
          right: 0;
          z-index: -1;
        }
        .services {
        }
      `}</style>
    </Layout>
  );
};

export default withTranslations()(Services);
