import React from 'react';
import Img from 'gatsby-image';
import Link from '../Link';

const ServicePreview = ({ name, id, description, buttonText, imageFluid, reverse, route }) => (
  <div className='preview' id={id}>
    <div className='text'>
      <h3 className='name'>{name}</h3>
      <p className='description'>
        {description.split('\n').map((item, key) => (
          <span key={key}>
            {item}
            <br />
          </span>
        ))}
      </p>
      <div className='button'>
        <a href={route}>
          <button>{buttonText}</button>
        </a>
      </div>
    </div>
    <div className='image'>
      <Img
        style={{ height: '100%', width: '100%' }}
        alt={`${name} illustration`}
        imgStyle={{ objectFit: 'cover' }}
        fluid={imageFluid}
      />
    </div>
    <style jsx>{`
      .preview {
        display: flex;
        flex-direction: ${reverse ? 'row-reverse' : 'row'};
        width: 750px;
        min-height: 300px;
        background-color: var(--background-white);
        margin-bottom: 30px;
        margin-right: ${!reverse ? 'auto' : '0'};
        margin-left: ${!reverse ? '0' : 'auto'};
        box-shadow: -10px 0px 30px rgba(0, 0, 0, 0.25);
      }
      .button {
        display: flex;
        flex-direction: ${!reverse ? 'row-reverse' : 'row'};
      }
      .image {
        flex: 0.75;
      }
      .text {
        flex: 1;
        padding: 30px;
        color: var(--primary-black);
      }
      .name {
        margin-bottom: 28px;
        font-size: 30px;
        color: var(--primary-black);
        font-weight: 600;
      }
      .description {
        margin-bottom: 30px;
      }

      @media only screen and (max-width: 800px) {
        .preview {
          display: flex;
          flex-direction: ${reverse ? 'row-reverse' : 'row'};
          background-color: var(--background-white);
          margin: 0 20px 70px 20px;
          width: auto;
        }
      }

      @media only screen and (max-width: 650px) {
        .preview {
          display: flex;
          flex-direction: column-reverse;
        }
        .button {
          display: flex;
          justify-content: center;
        }
        .image {
          height: 300px;
        }
      }
    `}</style>
  </div>
);

export default ServicePreview;
